import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtehortolandia.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3519071',
  schema: 'pbi_dte_hortolandia',
  groupId: 'ba31deb4-b4d8-448a-b147-ea6d12260aca',
  analiseGeral: 'adf723d0-7676-4811-9678-0645764b7955',
  analiseUsuarios: '3da9d54a-dfe5-44e5-92f8-1a688e89dc87',
  analiseComunicados: '08c9f290-1d4e-4c70-b19e-5268d6de256c',
  analiseProcurador: '6bbc917b-557e-484c-86b2-f43bbc13c6a1',
  analiseEventos: '9fca45ab-4358-4d91-b8c4-0753d2e1e543'
};
